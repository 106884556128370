import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
	},
	getters: {
	},
	mutations: {
		LOGOUT(state) {
			localStorage.removeItem("__TOKEN");
			localStorage.removeItem("userInfo");
			router.push({
				path: "/login",
			});
		}
	},
	actions: {
	},
	modules: {
	}
})
