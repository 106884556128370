//直接引入
import axios from "axios";
import router from "@/router";
import store from "@/store/index";

//因为axios默认发的是json格式数据，我们要做表单提交，需要更改axios配置
// 引入 Qs是为了把json格式，转为formdata 的数据格式
import Qs from "qs";

import { Message, Loading } from "element-ui";

let loading; //定义loading变量

function startLoading() {
	//使用Element loading-start 方法
	loading = Loading.service({
		lock: true,
		// text: '加载中……',
		background: "rgba(0, 0, 0, 0.7)",
	});
}

function endLoading() {
	//使用Element loading-close 方法
	loading.close();
}

if (process.env.NODE_ENV === "development") {
	axios.defaults.baseURL = "/";
} else if (process.env.NODE_ENV === "production") {
	// axios.get('./serverconfig.json').then(res => {
	//     if (res.data.baseUrl) {
	//         axios.defaults.baseURL = res.data.baseUrl
	//     } else {
	axios.defaults.baseURL = "/";
	//     }
	// })
}

// console.log(root)
const service = axios.create({
	// baseURL: root,
	timeout: 30000,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
	},
	transformRequest: [
		function (data) {
			// 对 data 进行任意转换处理
			return Qs.stringify(data);
		},
	],
});

// request 拦截器
service.interceptors.request.use(
	(config) => {
		// Tip: 1
		// 请求开始的时候可以结合 vuex 开启全屏的 loading 动画
		if (config.hide_loading) {
			startLoading();
		}

		// 辨别请求用户类型
		config.headers["api-type"] = "web";

		// Tip: 2
		// 带上 token , 可以结合 vuex 或者重 localStorage
		if (localStorage.getItem("__TOKEN")) {
			config.headers["user-authorization"] = localStorage.getItem("__TOKEN"); // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
		} else {
			// 重定向到登录页面
			// router.push({
			// 	path: '/login'
			// })
		}

		// 每个请求带上参数uid
		if (
			config.method.toLocaleLowerCase() === "get" &&
			localStorage.getItem("uid")
		) {
			config.params = {
				...config.params,
			};
			// console.log(config.params)
		}

		// Tip: 3
		// 根据请求方法，序列化传来的参数，根据后端需求是否序列化
		if (
			localStorage.getItem("uid") &&
			(config.method.toLocaleLowerCase() === "post" ||
				config.method.toLocaleLowerCase() === "put" ||
				config.method.toLocaleLowerCase() === "delete")
		) {
			config.data = {
				...config.data,
			};
			config.data = Qs.parse(config.data);
			// console.log(config.data)
		}
		return config;
	},
	(error) => {
		// 请求错误时做些事(接口错误、超时等)
		// Tip: 4
		// 关闭loadding
		if (loading) {
			endLoading();
		}
		console.log("request:", error);

		//  1.判断请求超时
		if (
			error.code === "ECONNABORTED" &&
			error.message.indexOf("timeout") !== -1
		) {
			console.log(
				"根据你设置的timeout/真的请求超时 判断请求现在超时了，你可以在这里加入超时的处理方案"
			);
			// return service.request(originalRequest);//例如再重复请求一次
		}
		//  2.需要重定向到错误页面
		const errorInfo = error.response;
		console.log(errorInfo);
		if (errorInfo) {
			// error =errorInfo.data//页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
			const errorStatus = errorInfo.status; // 404 403 500 ... 等
			router.push({
				path: `/error/${errorStatus}`,
			});
		}
		return Promise.reject(error); // 在调用的那边可以拿到(catch)你想返回的错误信息
	}
);

// response 拦截器
service.interceptors.response.use(
	(response) => {
		let data;
		// IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
		if (response.data == undefined) {
			data = response.request.responseText;
		} else {
			data = response.data;
		}
		// 根据返回的status值来做不同的处理（和后端约定）
		switch (data.status) {
			case 201:
				store.commit("SHOW_VIP", true);
				break;
			case 401:
				// token 过期 重新登录
				Message.error("登录超时,请重新登录");
				store.commit("LOGOUT");
				router.push({
					path: "/login",
				});
				return false;
			default:
		}
		// 若不是正确的返回status，且已经登录，就抛出错误
		// const err = new Error(data.description)

		// err.data = data
		// err.response = response

		// throw err
		if (loading) {
			endLoading();
		}
		return data;
	},
	(err) => {
		if (err && err.response) {
			switch (err.response.status) {
				case 400:
					err.message = "请求错误";
					break;

				case 401:
					err.message = "未授权，请登录";
					store.commit("LOGOUT");
					break;

				case 403:
					err.message = "拒绝访问";
					break;

				case 404:
					err.message = `请求地址出错: ${err.response.config.url}`;
					break;

				case 408:
					err.message = "请求超时";
					break;

				case 500:
					err.message = "服务器内部错误";
					break;

				case 501:
					err.message = "服务未实现";
					break;

				case 502:
					err.message = "网关错误";
					break;

				case 503:
					err.message = "服务不可用";
					break;

				case 504:
					err.message = "网关超时";
					break;

				case 505:
					err.message = "HTTP版本不受支持";
					break;

				default:
			}
		}
		console.error(err);
		// 此处我使用的是 element UI 的提示组件
		Message.error(`ERROR: ${err}`);
		if (loading) {
			endLoading();
		}
		return Promise.reject(err); // 返回接口返回的错误信息
	}
);

export default service;
