<template>
  <div id="app">
    <div class="main" :style="{ paddingTop: isShowNav ? '60px' : '0px' }">
      <nav v-if="isShowNav">
        <Navbar />
      </nav>
      <section :class="[isShowNav && 'is_padding']">
        <router-view></router-view>
      </section>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";
export default {
  name: "App",
  components: { Navbar },
  data() {
    return {
      isShowNav: false,
    };
  },
  watch: {
    $route(to) {
      this.isShowNav = to.path !== "/login";
    },
  },
};
</script>

<style lang="scss">
:root {
  --el-color-primary: #ff8717 !important;
  --el-fill-color-blank: transparent !important;
  --el-bg-color: #222222 !important;
  // --el-fill-color-light: #222222 !important;
  --el-bg-color-overlay: #222222 !important;
  --el-border-color-light: transparent !important;
  --el-color-primary-light-9: transparent !important;
  // --el-border-color: rgba(255, 255, 255, 0.2) !important;
  --el-color-primary-light-7: rgba(255, 255, 255, 0.3) !important;
  // --el-text-color-primary: rgba(255, 255, 255, 0.5) !important;
  // --el-text-color-regular: rgba(255, 255, 255, 0.5) !important;
  --el-border-color-lighter: rgba(255, 255, 255, 0.08) !important;
  --el-color-primary-dark-2: rgba(255, 135, 23, 8) !important;
  --el-color-primary-light-3: rgba(255, 135, 23, 7) !important;
}

.main {
  // width: 100vw;
  min-height: 100vh;
  padding-top: 60px;
  background: #111111;
}

section {
  box-sizing: border-box;
  min-height: calc(100vh - 45px);
}

.is_padding {
  padding: 20px;
}
</style>
