import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from "@/store/index";
import ElementUI from 'element-ui';
import * as echarts from "echarts";
import 'element-ui/lib/theme-chalk/index.css';
import "@/styles/index.scss";
import "@/styles/_variables.scss";

// 挂在路由导航守卫
router.beforeEach((to, from, next) => {
	if (to.path === '/login') return next()
	const tokenStr = window.localStorage.getItem('__TOKEN')
	// 没有token， 强制转到login页面
	if (!tokenStr) return next('/login')
	next()
})

import VueAMap from "vue-amap"

// 初始化vue-amap
VueAMap.initAMapApiLoader({
	// 高德的key
	key: 'cb2f37ea07b714fee16f357a3573cfbc',
	// 插件集合
	plugin: [
		'AMap.CircleEditor',// 圆形编辑器插件
		"AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
		"AMap.Geocoder", // 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
		"AMap.Autocomplete",
		"AMap.PlaceSearch",
		"AMap.CitySearch",
	],
	// 高德 sdk 版本，默认为 1.4.4
	v: '1.4.15'
});
//高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode: '16ffa1711d41d5239faf769921754916',
}

Vue.use(VueAMap)


Vue.use(ElementUI);
Vue.prototype.$store = store;
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
