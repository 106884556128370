<template>
  <div>
    <nav class="fc_jb">
      <div class="nav-menu fc">
        <div class="nav-logo fc f-9">
          <img src="@/assets/images/dashboard/logo.png" alt="" />
          <span>星企动·商家管理后台</span>
        </div>
        <div class="menu-list fc">
          <div
            v-for="item in menuList"
            :key="item.path"
            @click="handleClick(item.path)"
            :class="[
              'menu-item',
              item.path === activeMenu && 'active-menu-item',
            ]"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="nav-user fc">
        <template v-if="user_info.qr_only">
          <div class="venue-wrap fc">
            <el-popover placement="bottom" title="" trigger="hover">
              <vue-qr
                :size="150"
                :margin="0"
                :auto-color="true"
                :dot-scale="1"
                :text="user_info.qr_only"
              />
              <div slot="reference">
                <img src="@/assets/images/dashboard/venue-qrcode.png" alt="" />
                <span>场馆码</span>
              </div>
            </el-popover>
          </div>
          <div class="line-wrap"></div>
        </template>
        <div class="user-wrap fc">
          <img v-if="user_info.avatar" :src="user_info.avatar" alt="" />
          <el-dropdown @command="handleClickDropdown">
            <span class="el-dropdown-link">
              {{ user_info.nickname
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="user_info.is_main == 1"
                >主帐号：{{ user_info.user_no }}</el-dropdown-item
              >
              <el-dropdown-item command="edit_password">
                <i class="el-icon-edit-outline"></i>
                修改密码</el-dropdown-item
              >
              <el-dropdown-item command="login_out">
                <i class="el-icon-monitor"></i>
                退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </nav>
    <el-dialog title="修改密码" width="30%" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="ruleForm" :rules="rules">
        <el-form-item
          label="手机号"
          :label-width="formLabelWidth"
          prop="mobile"
        >
          <el-input
            v-model="form.mobile"
            maxlength="11"
            disabled
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
          :label-width="formLabelWidth"
          prop="password"
        >
          <el-input
            placeholder="请输入新密码"
            v-model="form.password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" :label-width="formLabelWidth" prop="code">
          <el-input v-model="form.code" placeholder="请输入验证码">
            <template #append>
              <span class="getcode" @click="getMsg">{{ codeBtnText }}</span>
            </template> </el-input
          > 
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { httpGet, httpPost } from "@/apis";
import VueQr from "vue-qr";
export default {
  data() {
    return {
      user_info: JSON.parse(localStorage.getItem("userInfo") || "{}"),
      activeMenu: "/dashboard",
      menuList: [],
      navs: [
        {
          title: "工作台",
          path: "/dashboard",
        },
        {
          title: "职工消费订单",
          path: "/order-manage",
          is_main: 0,
        },
        {
          title: "协会消费订单",
          path: "/association-order",
          is_main: 0,
        },
        {
          title: "场馆管理",
          path: "/venue-manage",
          is_main: 0,
        },
        {
          title: "套餐管理",
          path: "/package-list",
          is_main: 0,
        },
        {
          title: "账号管理",
          path: "/account-manage",
        },
        {
          title: "数据统计",
          path: "/statistics",
        },
        {
          title: "财务管理",
          path: "/finance-manage",
        },
      ],
      dialogFormVisible: false,
      formLabelWidth: "80px",
      form: {
        mobile: "",
        password: "",
        code: "",
      },
      rules: {
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      btnOptFlag: false,
      codeBtnText: "获取验证码",
      outTime: 60, // 设置发送验证码倒计时时间
      timer: null,
    };
  },
  watch: {
    $route(to, from) {
      this.menuList.forEach((item) => {
        if (to.path.indexOf(item.path) >= 0) {
          this.activeMenu = item.path;
        }
      });
    },
  },
  components: {
    "vue-qr": VueQr,
  },
  mounted() {
    if (this.user_info.is_main == 0) {
      this.menuList = this.navs.filter(
        (v) => v.is_main == this.user_info.is_main
      );
    } else {
      this.menuList = this.navs;
    }

    this.menuList.forEach((item) => {
      if (this.$route.path.indexOf(item.path) >= 0) {
        this.activeMenu = item.path;
      }
    });

    this.form.mobile = this.user_info.mobile;
  },
  methods: {
    handleClickDropdown(val) {
      if (val === "edit_password") {
        this.dialogFormVisible = true;
      } else if (val === "login_out") {
        this.$confirm("确定退出系统吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$store.commit("LOGOUT");
        });
      }
    },
    handleClick(path) {
      this.activeMenu = path;
      if (path === "/venue-manage" && this.user_info.is_main == 0) {
        this.$router.push(
          "/venue-manage/venue-detail?id=" + this.user_info.shop_id
        );
        return;
      }
      this.$router.push(path);
    },
    async getMsg() {
      if (this.btnOptFlag) return;
      if (!this.form.mobile) {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
        return;
      }
      const res = await httpPost("/shop/Forget/getForgetBySMS", {
        mobile: this.form.mobile,
      });
      if (res.status === 200) {
        this.$message({
          message: res.message,
          type: "success",
        });
        this.btnOptFlag = true; // 禁用发送验证码按钮
        this.timer = setInterval(() => {
          this.codeBtnText = this.outTime + "s后重新发送";
          this.outTime = this.outTime - 1;
          if (this.outTime < 0) {
            // 倒计时结束，重置时间器clearInterval(this.timer)
            clearInterval(this.timer);
            this.codeBtnText = "获取验证码";
            this.outTime = 60;
            this.timer = false;
            this.btnOptFlag = false;
          }
        }, 1000);
      } else {
        this.$message({
          message: res.message,
          type: "error",
          duration: 2000,
        });
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          httpPost("/shop/Forget/forSMS", this.form).then((res) => {
            if (res.status === 200) {
              this.dialogFormVisible = false;
              this.$message({
                message: "修改成功，请重新登录!",
                type: "success",
              });
              this.$store.commit("LOGOUT");
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 60px;
  box-sizing: border-box;
  padding: 14px 30px 15px;
  background: #222222;
  box-shadow: 0px 5px 8px 0px
    rgba(
      94.0000019967556,
      113.00000086426735,
      181.0000044107437,
      0.0784313753247261
    );
}

.nav-menu {
  .nav-logo {
    margin-right: 42px;
    @include font-medium-bold-style(14px);

    img {
      width: 32px;
      height: 31px;
      margin-right: 12px;
    }
  }

  .menu-list {
    .menu-item {
      padding: 9px 19px;
      @include font-style(14px);
      @include white-rgba(0.9);
      cursor: pointer;
    }

    .active-menu-item {
      background: $theme-color;
      border-radius: 5px;
    }
  }
}

.nav-user {
  @include font-style(14px);
  color: $white;

  .venue-wrap {
    box-sizing: border-box;
    width: 97px;
    height: 32px;
    margin-right: 8px;
    padding: 8px 12px;
    background: rgba($color: #ffffff, $alpha: 0.05);
    border-radius: 6px;

    img {
      width: 15px;
      height: 15px;
      margin-right: 7px;
    }
  }
}

.line-wrap {
  width: 1px;
  height: 20px;
  margin: 0 19px 0 18px;
  background: rgba($color: #fff, $alpha: 0.08);
}

.user-wrap {
  color: #ffffff;

  .el-icon {
    font-size: 14px;
  }

  img:first-child {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  span {
    padding: 0 8px;
  }
}

/deep/ .el-dropdown {
  height: 60px;
  @include font-style(14px);
  color: $white;
  line-height: 60px;
  cursor: pointer;
}

/deep/ .el-dropdown-menu__item {
  display: flex;
  align-items: center;
  @include font-style(14px);
}

/deep/ .user-wrap span {
  align-items: center;
}
</style>