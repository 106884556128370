import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: "/dashboard",
		component: () => import("@/views/dashboard/dashboard.vue"),
		children: [
			{
				path: "/dashboard",
				name: "dashboard",
				component: () => import("@/views/dashboard/dashboard.vue"),
			},
		],
	},
	{
		path: "/article/index",
		name: "article_index",
		component: () => import("@/views/article/index.vue"),
	},
	{
		path: "/article/detail",
		name: "article_detail",
		component: () => import("@/views/article/detail.vue"),
	},
	{
		path: "/package-list",
		name: "package-list",
		component: () => import("@/views/package-list/package-list.vue"),
	},
	{
		path: "/post-item",
		name: "post-item",
		component: () => import("@/views/post-item/post-item.vue"),
	},
	{
		path: "/order-manage",
		name: "order-manage",
		component: () => import("@/views/order-manage/order-manage.vue"),
	},
	{
		path: "/association-order",
		name: "association-order",
		component: () => import("@/views/association-order/association-order.vue"),
	},
	{
		path: "/venue-manage",
		name: "venue-manage",
		component: () => import("@/views/venue-manage/venue-manage.vue"),
	},
	{
		path: "/venue-manage/venue-detail",
		name: "venue-detail",
		component: () => import("@/views/venue-manage/venue-detail/venue-detail.vue"),
	},
	{
		path: "/venue-manage/album-list",
		name: "album-list",
		component: () => import("@/views/venue-manage/album-list/album-list.vue"),
	},
	{
		path: "/venue-manage/edit-venueInfo",
		name: "edit-venueInfo",
		component: () =>
			import("@/views/venue-manage/edit-venueInfo/edit-venueInfo.vue"),
	},
	{
		path: "/account-manage",
		name: "account-manage",
		component: () => import("@/views/account-manage/account-manage.vue"),
	},
	{
		path: "/statistics",
		name: "statistics",
		component: () => import("@/views/statistics/statistics.vue"),
	},
	{
		path: "/finance-manage",
		name: "finance-manage",
		component: () => import("@/views/finance-manage/finance-manage.vue"),
	},
	{
		path: "/finance-manage/withdraw",
		name: "withdraw",
		component: () => import("@/views/finance-manage/withdraw/withdraw.vue"),
	},
	{
		path: "/finance-manage/public-manage",
		name: "public-manage",
		component: () => import("@/views/finance-manage/public-manage/public-manage.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/login/login.vue"),
	},
	{
		path: "/401",
		name: "401",
		component: () => import("@/views/error-page/401.vue"),
	},
	{
		path: "/404",
		name: "404",
		component: () => import("@/views/error-page/404.vue"),
	},
];

const router = new VueRouter({
	routes,
});

export default router;
